/** @jsx jsx */
import { jsx } from 'theme-ui';

const Ul = props => <ul sx={{ marginBottom: 4 }} {...props} />;
const Li = props => (
  <li
    sx={{
      variant: 'text.body',
      marginBottom: 1,
      marginLeft: 4,
      listStyle: 'disc',
    }}
    {...props}
  />
);

export { Ul, Li };
