/** @jsx jsx */
import { jsx } from 'theme-ui';

import { P } from '../../components/Text';
import { Ul, Li } from '../../components/List';
import { CodeOfConductLayout } from '../../layouts/CodeOfConductLayout';

const CodeOfConductPage = () => {
  return (
    <CodeOfConductLayout>
      <P sx={{ fontSize: '24px' }}>
        IxDA cherche à faire progresser la discipline du design d’interaction en
        favorisant la création d’une communauté de personnes passionnées et
        dévouées à l’avancement de notre mission.
      </P>
      <P>
        Notre mission est large et inclusive, nos membres passionnés et divers.
        Ces traits font de IxDA une organisation forte et vibrante, mais ils
        peuvent aussi susciter des désaccords et une dissociation culturelle. À
        titre d’organisme dirigé par des bénévoles, nous comptons sur la
        participation active de notre communauté, ce qui est seulement possible
        si tous nos membres se sentent en sécurité et respectés.
      </P>
      <P>
        Pour atteindre ce but, IxDA demande respectueusement à ses membres, à la
        fois en ligne et dans les interactions personnelles sous sa marque, ce
        qui suit :
      </P>
      <Ul>
        <Li>
          Faire preuve de considération et de respect pour les autres cultures,
          disciplines et points de vue
        </Li>
        <Li>
          Conserver une attitude professionnelle et s’abstenir de tout
          harcèlement ou attitude d’exclusion envers d’autres membres
        </Li>
        <Li>
          Faire de son mieux pour résoudre les malentendus directement par la
          conversation ouverte et des commentaires respectueux
        </Li>
        <Li>
          Aviser les organisateurs ou les modérateurs si vous pensez avoir subi
          du harcèlement ou en avoir été témoin
        </Li>
      </Ul>
      <P>
        Si un désaccord, du harcèlement ou une situation inconfortable persiste,
        IxDA s’engage à fournir du soutien dans la résolution. Dans un lieu
        physique tel qu’une conférence ou un événement local, contactez
        immédiatement les organisateurs. Les participants à qui l’on demande
        d’arrêter tout comportement harcelant devront se conformer
        immédiatement. De plus, les modérateurs sur nos forums en ligne peuvent
        prendre les mesures nécessaires envers les membres qui créent un
        environnement hostile pour les autres. Les conséquences peuvent inclure
        le retrait du forum et la suspension du compte IxDA du membre concerné.
      </P>
      <P>
        Nous vous encourageons à communiquer avec la direction de IxDA à tout
        moment pour signaler un problème ou faire des commentaires sur nos
        efforts pour créer un environnement accueillant et respectueux pour
        tous.
      </P>
      <P>
        Si vous désirez contacer directement les membres de notre direction,
        voici comment:
      </P>
      <Ul>
        <Li>
          Brenda Sanderson, Directrice Exécutive -{' '}
          <a
            href="mailto:bsanderson@ixda.org"
            sx={{ variant: 'variants.link', fontWeight: 'regular' }}
          >
            bsanderson@ixda.org
          </a>
          ,{' '}
          <a
            href="tel:+15142970406"
            sx={{ variant: 'variants.link', fontWeight: 'regular' }}
          >
            +1 514 297 0406
          </a>
        </Li>
        <Li>
          Kevin Budelmann, Président -{' '}
          <a
            href="mailto:president@ixda.org"
            sx={{ variant: 'variants.link', fontWeight: 'regular' }}
          >
            president@ixda.org
          </a>
        </Li>
        <Li>
          Andrea Mignolo, Vice Présidente -{' '}
          <a
            href="mailto:vp@ixda.org"
            sx={{ variant: 'variants.link', fontWeight: 'regular' }}
          >
            vp@ixda.org
          </a>
        </Li>
      </Ul>
    </CodeOfConductLayout>
  );
};

export default CodeOfConductPage;
